<!--
 Name: ValidationStrategy.vue
 Description: View to display the validation strategy page.
 Created by: Jana Schumann on 2019-11-10
 Last edited by: Lokeswari Madhusudhana on 2023-06-27
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-12">
      <h2 class="my-12">{{ $t("validation.headline") }}</h2>
      <p class="mb-3">{{ $t("validation.text-1") }}</p>
      <ul class="pa-4">
        <li>{{ $t("validation.text-1-bullet-1") }}</li>
        <li>{{ $t("validation.text-1-bullet-2") }}</li>
      </ul>
      <br/>
      <p>{{ $t("validation.text-2") }}</p>
    </v-container>

    <!-- validation phases -->
    <section class="violet_light py-3">
      <v-container>
        <v-row justify="center">
          <v-hover v-slot:default="{ hover }">
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="px-5 mt-11 hover-me"
              @click="scrollPhaseOne"
            >
              <v-img
                :src="hover ? phase1_over : phase1"
                class="my-12"
                height="150px"
              />

              <div class="text-center">
                <p class="mb-3">
                  <b class="phase-title">{{ $t("validation.phase-1-headline") }}</b>
                </p>
                <p class="semi-small phase-description">{{ $t("validation.phase-1-subheadline") }}</p>
              </div>
            </v-col>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="px-5 mt-11 hover-me"
              @click="scrollPhaseTwo"
            >
              <v-img
                :src="hover ? phase2_over : phase2"
                class="my-12"
                height="150px"
              />

              <div class="text-center">
                <p class="mb-3">
                  <b class="phase-title">{{ $t("validation.phase-2-headline") }}</b>
                </p>
                <p class="semi-small phase-description">{{ $t("validation.phase-2-subheadline") }}</p>
              </div>
            </v-col>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="px-5 mt-11 hover-me"
              @click="scrollPhaseThree"
            >
              <v-img
                :src="hover ? phase3_over : phase3"
                class="my-12"
                height="150px"
              />

              <div class="text-center">
                <p class="mb-3">
                  <b class="phase-title">{{ $t("validation.phase-3ab-headline") }}</b>
                </p>
                <p class="semi-small phase-description">{{ $t("validation.phase-3ab-subheadline") }}</p>
              </div>
            </v-col>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="px-5 mt-11 hover-me"
              @click="scrollPhaseFour"
            >
              <v-img
                :src="hover ? phase4_over : phase4"
                class="my-12"
                height="150px"
              />

              <div class="text-center">
                <p class="mb-3">
                  <b class="phase-title">{{ $t("validation.phase-4-headline") }}</b>
                </p>
                <p class="semi-small phase-description">{{ $t("validation.phase-4-subheadline") }}</p>
              </div>
            </v-col>
          </v-hover>
        </v-row>
      </v-container>
    </section>

    <!-- phase 1 -->
    <section class="violet_light pb-3" id="phase1">
      <v-container>
        <v-row class="pt-8 mt-4">
          <v-col cols="12" md="6">
            <div class="square-left bg-primary mb-8"></div>
            <h2 class="mb-3 text-primary">
              {{ $t("validation.phase-1-headline") }}
            </h2>
            <p class="mb-12">
              <b>{{ $t("validation.phase-1-subheadline") }}</b>
            </p>
            <p class="mb-3">{{ $t("validation.phase-1-text-1") }}</p>
            <p class="mb-3">{{ $t("validation.phase-1-text-2") }}</p>
            <p class="mb-3">{{ $t("validation.phase-1-text-3") }}</p>
          </v-col>
          <v-col cols="12" md="6" class="align-self-center">
              <v-img :src="phase1" height="200px"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- phase 2 -->
    <section class="violet_light pb-3" id="phase2">
      <v-container>
        <v-row class="pt-12 mt-12">
          <v-col cols="12" md="6" order="2" order-md="1" class="align-self-center">
              <v-img :src="phase2" height="200px"></v-img>
          </v-col>
          <v-col cols="12" md="6" order="1" order-md="2">
            <div class="square-left bg-primary mb-8"></div>
            <h2 class="mb-3 text-primary">
              {{ $t("validation.phase-2-headline") }}
            </h2>
            <p class="mb-12">
              <b>{{ $t("validation.phase-2-subheadline") }}</b>
            </p>
            <p class="mb-3">{{ $t("validation.phase-2-text-1") }}</p>
            <p class="mb-3">{{ $t("validation.phase-2-text-2") }}</p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- phase 3 -->
    <section class="violet_light pb-3" id="phase3">
      <v-container>
        <v-row class="pt-12 mt-12">
          <v-col cols="12" md="6">
            <div class="square-left bg-primary mb-8"></div>
            <h2 class="mb-3 text-primary">
              {{ $t("validation.phase-3a-headline") }}
            </h2>
            <p class="mb-12">
              <b>{{ $t("validation.phase-3a-subheadline") }}</b>
            </p>
            <p class="mb-3">{{ $t("validation.phase-3a-text-1") }}</p>
            <h2 class="mb-3 text-primary">
              {{ $t("validation.phase-3b-headline") }}
            </h2>
            <p class="mb-12">
              <b>{{ $t("validation.phase-3b-subheadline") }}</b>
            </p>
            <p class="mb-3">{{ $t("validation.phase-3b-text-1") }}</p>
            <p class="mb-3">{{ $t("validation.phase-3b-text-2") }}</p>
          </v-col>
          <v-col cols="12" md="6" class="align-self-center">
              <v-img :src="phase3" height="200px"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- phase 4 -->
    <section class="violet_light" id="phase4">
      <v-container>
        <v-row class="pt-12 my-12">
          <v-col cols="12" md="6" order="2" order-md="1" class="align-self-center">
              <v-img :src="phase4" height="200px"></v-img>
          </v-col>
          <v-col cols="12" md="6" order="1" order-md="2">
            <div class="square-left bg-primary mb-8"></div>
            <h2 class="mb-3 text-primary">
              {{ $t("validation.phase-4-headline") }}
            </h2>
            <p class="mb-12">
              <b>{{ $t("validation.phase-4-subheadline") }}</b>
            </p>
            <p class="mb-3">{{ $t("validation.phase-4-text-1") }}</p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-container class="my-12">
      <!-- standards -->
      <h3 class="mb-5">{{ $t("validation.standards-headline") }}</h3>
      <p class="mb-5">{{ $t("validation.standards-text") }}</p>
    </v-container>

    <!-- science behind neotiv -->
    <section class="bg-primary">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("studies.science-behind-neotiv") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/studies'"
            >{{ $t("footer.science.studies") }}</v-btn
          >
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/papers'"
            >{{ $t("studies.papers") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {
  computed: {
    phase1() {
      return require("@/assets/images/validation/phase1.svg");
    },
    phase2() {
      return require("@/assets/images/validation/phase2.svg");
    },
    phase3() {
      return require("@/assets/images/validation/phase3.svg");
    },
    phase4() {
      return require("@/assets/images/validation/phase4.svg");
    },
    phase1_over() {
      return require("@/assets/images/validation/phase1_over.svg");
    },
    phase2_over() {
      return require("@/assets/images/validation/phase2_over.svg");
    },
    phase3_over() {
      return require("@/assets/images/validation/phase3_over.svg");
    },
    phase4_over() {
      return require("@/assets/images/validation/phase4_over.svg");
    }
  },
  methods: {
    scrollPhaseOne() {
      const element = document.getElementById('phase1');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    scrollPhaseTwo() {
      const element = document.getElementById('phase2');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    scrollPhaseThree() {
      const element = document.getElementById('phase3');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    scrollPhaseFour() {
      const element = document.getElementById('phase4');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
.hover-me:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #57509c;
}

.phase-title {
  font-size: 20px;
}

.phase-description {
  line-height: 1.3;
}
</style>
