<!--
 Name: CitizenScience.vue
 Description: View to display the citizen science page.
 Created by: Jana Schumann on 2019-11-10
 Last edited by: Lokeswari Madhusudhana on 2023-06-02
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-15">
      <h2 class="mt-12 mb-15">{{ $t("cs.headline") }}</h2>

      <v-row>
        <v-col cols="12" md="6" class="mb-12">
          <div class="square-left bg-primary mb-3"></div>
          <h3 class="mb-3">{{ $t("cs.main") }}</h3>
          <p class="mb-12">{{ $t("cs.text") }}</p>
          <v-btn
            variant="flat"
            rounded
            size="large"
            color="bg-primary"
            href="https://citizen-science.neotiv.com"
            target="_blank"
          >
            {{ $t("cs.to_cs") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="5" offset-md="1" class="mb-5">
          <v-img :src="cs" aspect-ratio="1.21"></v-img>
        </v-col>
      </v-row>
    </v-container>

    <!-- science behind neotiv -->
    <section class="bg-primary">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("studies.science-behind-neotiv") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/studies'"
            >{{ $t("footer.science.studies") }}</v-btn
          >
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/papers'"
            >{{ $t("studies.papers") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {
  computed: {
    cs() {
      return require("@/assets/images/cs/cs_project.svg");
    }
  }
};
</script>
