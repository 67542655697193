/**
 * Name: index.js
 * Description: Config file for the axios instance.
 * Created by: Lokeswari Madhusudhana on 2023-09-04
 * Last edited by: Lokeswari Madhusudhana on 2023-09-11
 */

import axios from 'axios'

const axiosInstance = axios.create()

const API_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
}
const BASE_URL_MAP = {
  [API_ENVIRONMENTS.DEVELOPMENT]: 'https://dev-api.neotiv.com',
  [API_ENVIRONMENTS.PRODUCTION]: 'https://api.neotiv.com'
}
axiosInstance.interceptors.request.use(config => {
  if (process.env.VUE_APP_API === 'development') {
    config.headers['x-api-key'] = process.env.VUE_APP_API_KEY
  } else {
    config.headers['x-api-key'] = process.env.VUE_APP_API_KEY
  }
  config.baseURL = BASE_URL_MAP[process.env.VUE_APP_API]
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  return config
})

export default axiosInstance
