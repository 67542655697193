<!--
 Name: NewsBanner.vue
 Description: Component for the animated news banner.
 Created by: Milena Malysheva on 2021-07-09
 Last edited by: Lokeswari Madhusudhana on 2023-06-02
-->

<template>
  <a
    href="https://neotiv-care.com"
    target="_blank"
    class="news-color"
  >
    <v-row align="center" no-gutters class="pr-md-7 news-banner text-banner">
      <v-img
        class="hidden-sm-and-down"
        height="60"
        max-width="70"
        cover
        :src="require('@/assets/images/home/news_banner/banner_ribbon.svg')"
      />
      <v-col class="overflow-hidden" ref="banner">
        
        <div
          ref="bannerText"
          :class="$vuetify.display.mdAndDown ? 'pa-4 pa-md-0 text-center': 'running-text'"
        >
          <div
            ref="bannerTextBlock"
            class="d-inline-block"
            v-for="n in textBlockCount"
            :key="`banner-text-block-${n}`"
          >
            <v-avatar>
              <v-img
                :src="require('@/assets/images/home/news_banner/logo_neotivCare_circle.png')"
                alt="neotivCare"
              />
            </v-avatar>
            <span class="news-banner-span">{{ $t('home.newsbanner') }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </a>
</template>

<script defer>
export default {
  data: () => ({
    textBlockCount: 1,
    textBlockRO: null,
    bannerAnimation: null,
  }),
  methods: {
    initBanner () {
      if (this.$vuetify.display.mdAndDown) {
        this.textBlockCount = 1
      } else {
        this.$nextTick(() => {
          const bannerWidth = this.$refs.bannerText.clientWidth;
          const textBlockWidth = this.$refs.bannerTextBlock[0].clientWidth;
          this.textBlockCount = Math.ceil(bannerWidth / textBlockWidth) + 1;
        });
      }
    }
  },
  watch: {
    textBlockCount(val) {
      if (this.bannerAnimation) this.bannerAnimation.cancel()
      if (val > 1) this.bannerAnimation = this.$refs.bannerText.animate(
        [
          { transform: 'translateX(0%)'},
          { transform: `translateX(-${ 100 / val }%)`},
        ], {
          duration: 22000,
          iterations: Infinity
        }
      );
    }
  },
  mounted() {
    // v-resize directive can't be used to detect the font being loaded event ->
    // -> this.$refs.bannerTextBlock[0].clientWidth value is not precise
    // using $nextTick, you ensure that the DOM elements are fully rendered before accessing their properties.
    this.$nextTick(() => {
      const bannerElement = this.$refs.banner;
      const bannerTextBlockElement = this.$refs.bannerTextBlock && this.$refs.bannerTextBlock[0];

      if (bannerElement instanceof Element && bannerTextBlockElement instanceof Element) {
        this.textBlockRO = new ResizeObserver(() => {
          this.initBanner();
        });
    
        this.textBlockRO.observe(bannerElement);
        this.textBlockRO.observe(bannerTextBlockElement);
      }
    });
    this.initBanner()
  },
  beforeUnmount () {
    this.$nextTick(() => {
      if (this.$refs.bannerTextBlock && this.$refs.bannerTextBlock[0]) {
        this.textBlockRO.unobserve(this.$refs.banner);
        this.textBlockRO.unobserve(this.$refs.bannerTextBlock[0]);
      }
    })
  }
};
</script>

<style scoped>
  .news-banner{
    background-color: #ffffff;
    transition: background-color 0.2s linear;
    cursor: pointer;
  }

  .news-banner:hover{
    background-color: #efefef!important;
  }

  .running-text {
    width: fit-content;
    white-space: nowrap;
  }

  .running-text > div > span{
    padding-right: 800px;
  }

  .news-banner-span {
    margin-left: 10px;
  }

  .news-color {
    color: black;
  }

</style>