<!--
 Name: PrivacyPolicyTrials.vue
 Description: View to display the privacy policy of neotivTrials page.
 Created by: Jana Schumann on 2023-07-20
 Last edited by: Jana Schumann on 2023-07-20
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-5">
      <h2 class="my-5">{{ $t("pptrials.headline") }}</h2>
      <p class="mb-3">
        <b>{{ $t("pptrials.date") }}</b>
      </p>
      <p>
        {{ $t("pptrials.introduction") }}
      </p>
      <!-- 1. -->
      <h3 class="my-5">{{ $t("pptrials.headline-one") }}</h3>
      <p>
        {{ $t("pptrials.text-one") }}
      </p>
      <!-- 2. -->
      <h3 class="my-5">{{ $t("pptrials.headline-two") }}</h3>
      <p class="mb-3">
        {{ $t("pptrials.text-two-one-gmbh") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text-two-one-data") }}
      </p>
      <p>
        {{ $t("pptrials.text-two-two") }}
      </p>
      <!-- 3. -->
      <h3 class="my-5">{{ $t("pptrials.headline-three") }}</h3>
      <p class="mb-3">
        {{ $t("pptrials.text-three-one") }}
      </p>
      <!-- 3.1.1 -->
      <p class="mb-3">
        <b>{{ $t("pptrials.headline-three-one-one") }}</b>
      </p>
      <ul class="ml-10 mb-3">
        <li>{{ $t("pptrials.point1-three-one-one") }}</li>
        <li>{{ $t("pptrials.point2-three-one-one") }}</li>
      </ul>
      <!-- 3.1.1.1 -->
      <p class="mb-3">
        <b>{{ $t("pptrials.headline-three-one-one-one") }}</b>
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text-three-one-one-one") }}
      </p>
      <!-- 3.1.1.2 -->
      <p class="mb-3">
        <b>{{ $t("pptrials.headline-three-one-one-two") }}</b>
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text-three-one-one-two") }}
      </p>
      <!-- 3.1.1.3 -->
      <p class="mb-3">
        <b>{{ $t("pptrials.headline-three-one-one-three") }}</b>
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text-three-one-one-three") }}
      </p>
      <!-- 3.1.2 -->
      <p class="mb-3">
        <b>{{ $t("pptrials.headline-three-one-two") }}</b>
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text-three-one-two") }}
      </p>
      <!-- 3.1.3 -->
      <p class="mb-3">
        <b>{{ $t("pptrials.headline-three-one-three") }}</b>
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text-three-one-three") }}
      </p>
      <!-- 3.2 -->
      <h4 class="my-5">{{ $t("pptrials.headline-three-two") }}</h4>
      <p class="mb-3">
        {{ $t("pptrials.text1-three-two") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text2-three-two") }}
      </p>
      <ul class="ml-10 mb-3">
        <li>{{ $t("pptrials.point1-three-two") }}</li>
        <li>{{ $t("pptrials.point2-three-two") }}</li>
        <li>{{ $t("pptrials.point3-three-two") }}</li>
        <li>{{ $t("pptrials.point4-three-two") }}</li>
      </ul>
      <p class="mb-3">
        {{ $t("pptrials.text3-three-two") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text4-three-two") }}
      </p>
      <!-- 3.3 -->
      <h4 class="my-5">{{ $t("pptrials.headline-three-three") }}</h4>
      <p class="mb-3">
        {{ $t("pptrials.text1-three-three") }}
      </p>
      <ul class="ml-10 mb-3">
        <li>{{ $t("pptrials.point1-three-three") }}</li>
        <li>{{ $t("pptrials.point2-three-three") }}</li>
        <li>{{ $t("pptrials.point3-three-three") }}</li>
        <li>{{ $t("pptrials.point4-three-three") }}</li>
        <li>{{ $t("pptrials.point5-three-three") }}</li>
        <li>{{ $t("pptrials.point6-three-three") }}</li>
        <li>{{ $t("pptrials.point7-three-three") }}</li>
      </ul>
      <p class="mb-3">
        {{ $t("pptrials.text2-three-three") }}
      </p>
      <!-- 3.4 -->
      <h4 class="my-5">{{ $t("pptrials.headline-three-four") }}</h4>
      <p class="mb-3">
        {{ $t("pptrials.text1-three-four") }}
      </p>
      <ul class="ml-10 mb-3">
        <li>{{ $t("pptrials.point1-three-four") }}</li>
        <li>{{ $t("pptrials.point2-three-four") }}</li>
        <li>{{ $t("pptrials.point3-three-four") }}</li>
        <li>{{ $t("pptrials.point4-three-four") }}</li>
        <li>{{ $t("pptrials.point5-three-four") }}</li>
        <li>{{ $t("pptrials.point6-three-four") }}</li>
        <li>{{ $t("pptrials.point7-three-four") }}</li>
        <li>{{ $t("pptrials.point8-three-four") }}</li>
        <li>{{ $t("pptrials.point9-three-four") }}</li>
        <li>{{ $t("pptrials.point10-three-four") }}</li>
      </ul>
      <p class="mb-3">
        {{ $t("pptrials.text2-three-four") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text3-three-four") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text4-three-four") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text5-three-four") }}
      </p>
      <!-- 3.5 -->
      <h4 class="my-5">{{ $t("pptrials.headline-three-five") }}</h4>
      <p class="mb-3">
        {{ $t("pptrials.text1-three-five") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text2-three-five") }}
      </p>
      <!-- 4. -->
      <h2 class="my-5">{{ $t("pptrials.headline-four") }}</h2>
      <p class="mb-3">
        {{ $t("pptrials.text-four") }}
      </p>
      <!-- 5. -->
      <h2 class="my-5">{{ $t("pptrials.headline-five") }}</h2>
      <p class="mb-3">
        {{ $t("pptrials.text-five") }}
      </p>
      <!-- 6. -->
      <h2 class="my-5">{{ $t("pptrials.headline-six") }}</h2>
      <p class="mb-3">
        {{ $t("pptrials.text1-six") }}
      </p>
      <ul class="ml-10 mb-3">
        <li>{{ $t("pptrials.point1-six") }}</li>
        <li>{{ $t("pptrials.point2-six") }}</li>
        <li>{{ $t("pptrials.point3-six") }}</li>
        <li>{{ $t("pptrials.point4-six") }}</li>
      </ul>
      <p class="mb-3">
        {{ $t("pptrials.text2-six") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text3-six") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text4-six") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.text5-six") }}
      </p>
      <p class="mb-3">
        <b>{{ $t("pptrials.authority1") }}</b>
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority2") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority3") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority4") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority5") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority6") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority7") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority8") }}
      </p>
      <p class="mb-3">
        {{ $t("pptrials.authority9") }}
      </p>
    </v-container>
  </v-container>
</template>

<script>
</script>