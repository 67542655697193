<!--
 Name: 404.vue
 Description: View to display the error fallback page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2023-06-02
-->

<template>
  <v-container fluid class="pa-0 full-width" style="background-color: #91b1c4">
    <v-container class="pb-15">
      <h2 class="my-12 text-center">{{ $t("error.headline") }}</h2>

      <v-img :src="dachshund" cover aspect-ratio="1"></v-img>
      <h2 class="text-white text-center mt-12 mb-15">{{ $t("error.text") }}</h2>
      <v-row justify="center" class="mb-0">
        <v-btn
          variant="flat"
          rounded
          ripple
          size="large"
          color="white error_blue--text"
          class="my-12"
          href="https://neotiv.com"
        >
          {{ $t("error.btn_home") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  computed: {
    dachshund() {
      return require("../assets/images/404/dachshund_error.svg");
    }
  }
};
</script>
