<!--
 Name: PaperItem.vue
 Description: Component to display a paper.
 Created by: Milena Malysheva on 2023-01-19
 Last edited by: Lokeswari Madhusudhana on 2023-06-16
-->

<template>
  <v-row>

    <!-- image, link -->
    <v-col cols="12" md="3">
      <a
        target="_blank"
        :href="paper.link"
        rel="noopener noreferrer"
      >
        <v-img
          :src="require(`@/assets/images/papers/${paper.id}.png`)"
          cover
        />
      </a>
      <v-row justify="center">
        <v-btn
          variant="outlined"
          rounded
          color="primary"
          class="mt-6 mb-3 mt-md-12 read-paper-btn"
          target="_blank"
          :href="paper.link"
          rel="noopener noreferrer"
        >
          {{ $t("papers.readPaper") }}
        </v-btn>
      </v-row>
    </v-col>

    <!-- paper details -->
    <v-col cols="12" md="8" offset-md="1">

      <!-- title -->
      <h3 class="mb-3">
        {{ paper.title }}
      </h3>

      <!-- description -->
      <p class="text-justify mb-5">
        {{ paper.description }}
      </p>

      <div class="text-grey small paper-additional-details">

        <!-- authors -->
        <div>
          <b>{{ $t("papers.authors") }}:</b>
          {{ paper.authors.slice(0, 4).join(', ') }}
          <v-tooltip
            v-if="paper.authors.length > 4"
            location="top"
            max-width="800"
          >
            <template #activator="{ props }">
              <v-chip
                class="ml-2"
                color="primary"
                small
                v-bind="props"
              >
                + {{ paper.authors.length - 4 }}
              </v-chip>
            </template>
            <span>
              {{ paper.authors.slice(4).join(', ') }}
            </span>
          </v-tooltip>
        </div>

        <!-- journal -->
        <div v-if="paper.journal">
          <b>{{ $t("papers.journal") }}:</b>
          {{ paper.journal }}
        </div>
        <!-- or book -->
        <div v-else>
          <b>{{ $t("papers.book") }}:</b>
          {{ paper.book }}
        </div>

        <!-- year -->
        <div>
          <b>{{ $t("papers.year") }}:</b>
          {{ paper.year }}
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    paper: Object
  }
};
</script>

<style scoped>
  .read-paper-btn {
    /* we need to remove !important from the App.vue to avoid using it here */
    font-size: 17px !important;
  }
  .paper-additional-details {
    line-height: 2;
  }
</style>