<!--
 Name: Partner.vue
 Description: View to display the partner page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2024-04-18
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- partner -->
    <section id="all">
      <v-container class="mb-15">
        <h2 class="my-12">{{ $t("partnerships.headline") }}</h2>
        <p class="text-justify mb-15">{{ $t("partnerships.text") }}</p>

        <v-row justify="space-between">
          <v-col cols="6" md="2">
            <a target="_blank" rel="noopener noreferrer" :href="urlIKND">
              <v-img :src="logoIKND" width="150px" cover></v-img>
            </a>
          </v-col>
          <v-col cols="6" md="2">
            <a target="_blank" rel="noopener noreferrer" :href="urlDZNE">
              <v-img :src="logoDZNE" width="150px" cover></v-img>
            </a>
          </v-col>
          <v-col cols="6" md="2">
            <a target="_blank" rel="noopener noreferrer" :href="urlOvGU">
              <v-img :src="logoOvGU" width="150px" cover></v-img>
            </a>
          </v-col>
          <v-col cols="6" md="2">
            <a target="_blank" rel="noopener noreferrer" :href="urlFH">
              <v-img :src="logoHTGF" width="200px" cover></v-img>
            </a>
          </v-col>
          <v-col cols="6" md="2">
            <a target="_blank" rel="noopener noreferrer" :href="urlHTGF">
              <v-img :src="logoFH" width="150px"  cover></v-img>
            </a>
          </v-col>
          <v-col cols="6" md="2">
            <a target="_blank" rel="noopener noreferrer" :href="urlADRIDDLE">
              <v-img :src="logoADRIDDLE" width="150px"  cover></v-img>
            </a>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-btn
            variant="outlined"
            rounded
            size="large"
            color="primary"
            class="my-12"
            href="mailto:partner@neotiv.com"
            >{{ $t("partnerships.be_partner") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {
  computed: {
    logoIKND() {
      return require("@/assets/images/partner/Logo_IKND.png");
    },
    logoDZNE() {
      return require("@/assets/images/partner/Logo_DZNE.jpg");
    },
    logoOvGU() {
      return require("@/assets/images/partner/Logo_OvGU.png");
    },
    logoFH() {
      return require("@/assets/images/partner/Logo_FH.png");
    },
    logoHTGF() {
      return require("@/assets/images/partner/Logo_HTGF.svg");
    },
    logoADRIDDLE() {
      return require("@/assets/images/partner/Logo_AdRiddle.png");
    },
    urlIKND() {
      return 'http://www.iknd.ovgu.de/'
    },
    urlDZNE() {
      return 'https://www.dzne.de/'
    },
    urlOvGU() {
      return 'https://www.ovgu.de/'
    },
    urlFH() {
      return 'https://www.flyinghealth.com/'
    },
    urlHTGF() {
      return 'https://www.htgf.de/de/'
    },
    urlADRIDDLE() {
      return 'https://ad-riddle.org/'
    }
  }
};
</script>
