<!--
 Name: RouterView.vue
 Description: View to display the router base.
 Created by: Jana Schumann on 2019-11-12
 Last edited by: Jana Schumann on 2019-11-14
-->

<template>
  <router-view></router-view>
</template>
