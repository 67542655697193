<!--
 Name: CookieBanner.vue
 Description: Component to display the cookie banner.
 Created by: Milena Malysheva on 2020-06-05
 Last edited by: Lokeswari Madhusudhana on 2023-06-26
-->

<template>
  <v-dialog
    :transition="false"
    v-model="dialog"
    persistent
    scrollable
    max-width="650"
    scrim="dialog_overlay_color"
  >
    <v-card class="pa-10">
      <v-card-title class="mb-6 pa-0">
        <b>{{ $t("cookieBanner.title") }}</b>
      </v-card-title>
      <!-- mr-n10 is a workaround to put the scrolling bar to the rightmost position -->
      <v-card-text class="dialog_text_color py-0 pl-0 pr-10 mr-n10">
        <p class="dialog_text_color">{{ $t("cookieBanner.text") }}
        <router-link 
          :to="`/${this.$i18n.locale}/data`"
          target="_blank"
          class="text-primary"
        >
          {{ $t("cookieBanner.privacy_policy_link") }}
        </router-link>
      </p>

        <!-- checkboxes -->
        <v-row no-gutters class="mt-4">
          <v-checkbox
            class="mr-0"
            color="primary"
            :value="group.statistics"
            v-for="(_, group) in groups"
            v-model="groups[group]"
            :key="`checkbox-${group}`"
            :disabled="group === 'essential'"
            :label="$t(`cookieBanner.groups.${group}.title`)"
          >
          </v-checkbox>
        </v-row>

        <v-divider/>

        <!-- show / hide details button -->
        <v-row no-gutters>
          <v-btn
            @click="showDetails = !showDetails"
            variant="text"
            color="accent"
            class="pl-0 my-3"
            :ripple="false"
          >
            <v-icon
              class="mr-2"
            >
            {{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
            {{ $t(`cookieBanner.${showDetails ? 'hide_details_btn' : 'show_details_btn'}`) }}
          </v-btn>
        </v-row>

        <!-- details -->
        <template v-if="showDetails">
          <div v-for="(_, group) in groups" :key="group">
            <b :key="`details-title-${group}`">
              {{ $t(`cookieBanner.groups.${group}.title`) }}
            </b>
            <p :key="`details-description-${group}`" class="mb-7">
              {{ $t(`cookieBanner.groups.${group}.description`) }}
            </p>
          </div>
        </template>
      </v-card-text>

      <!-- fixed dialog footer -->
      <v-divider/>

      <!-- div wrapper to make it work on iPhone 7/8 -->
      <div>
        <v-row
          justify="end"
          class="pt-7 pb-15"
        >
          <!-- mr-md-3 - to make the distance between the buttons the same as it is in neotiv-care -->
          <v-col cols="12" sm="5" class="mr-md-3">
            <v-btn
              class="confirm-btn"
              variant="outlined"
              size="large"
              block
              color="primary"
              @click="confirmSelection()"
              :disabled="!groups.essential"
            >
              {{ $t(`cookieBanner.confirm_selection_btn`) }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="5">
            <v-btn
              size="large"
              block
              color="primary"
              @click="selectAll()"
            >
              {{ $t(`cookieBanner.select_all_btn`) }}
            </v-btn>
          </v-col>
        </v-row>

        <!-- links legal -->
        <div class="small">
          <router-link
            :to="`/${$i18n.locale}/legal`"
            target="_blank"
            class="text_color"
            >{{ $t("footer.law.impressum") }}</router-link> |
          <router-link
            :to="`/${$i18n.locale}/data`"
            target="_blank"
            class="text_color"
          >{{ $t("footer.law.data") }}</router-link>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      groups: {
        essential: true,
        statistics: false
      },
      showDetails: false
    };
  },
  methods: {
    selectAll() {
      // we want to show the checkboxes checked before the dialog is closed
      for (let group of Object.keys(this.groups)) {
        this.groups[group] = true;
      }
      this.confirmSelection()
    },
    confirmSelection() {
      // we don't close the dialog explicitly cause it's closed anyway when the page is reloaded and we want to have
      // some time for a user to notice the checkboxes checked
      for (let [group, accepted] of Object.entries(this.groups)) {
        if (accepted) {
          localStorage.setItem(`cookie:${group}`, "true");
        } else {
          localStorage.removeItem(`cookie:${group}`);
        }
      }
      localStorage.setItem("cookie:policyVersion", process.env.VUE_APP_DATA_USE_POLICY_VERSION);
      // reloads the page
      // can be removed when _paq.push(['enableCookies']) will be implemented: https://github.com/matomo-org/matomo/issues/13056
      this.$router.go(0)
    },
    resetCookieLocalStorage() {
      for (let group of Object.keys(this.groups)) {
        localStorage.removeItem(`cookie:${group}`);
      }
    },
    setCheckboxes() {
      for (let group of Object.keys(this.groups)) {
        this.groups[group] = localStorage.getItem(`cookie:${group}`)
      }
      this.groups.essential = true
      if (localStorage.getItem(`cookie:statistics`)) {
        this.groups.statistics = true
      }
    }
  },
  mounted() {
    if (localStorage.getItem('cookie:policyVersion') !== process.env.VUE_APP_DATA_USE_POLICY_VERSION) {
      this.resetCookieLocalStorage();
    }
    this.setCheckboxes()
    this.dialog =
      this.$route.name !== "Legal" &&
      this.$route.name !== "Data" &&
      !localStorage.getItem("cookie:essential");
  }
};
</script>
<style scoped>
  .v-input .v-label {
    font-size: inherit !important;
  }

  .v-dialog--scrollable > .v-card > .v-card__text {
    width: auto;
  }

  .v-overlay__scrim {
    opacity: 0.7;
  }

  .v-card .v-card-title {
    font-size: 30px!important;
    font-family: "Hind", sans-serif !important;
  }

  .v-card .v-card-text {
    color: #8B8B8B;
    font-family: "Hind", sans-serif !important;
  }

  .text_color {
    color: #8B8B8B
  }

  .v-btn {
    border-style: none;
  }

  .confirm-btn {
    border-style: solid;
  }

  .v-input {
    flex: 0 1 auto;
  }
</style>
