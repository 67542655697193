<!--
 Name: Contact.vue
 Description: View to display the contact page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Milena Malysheva on 2022-03-14
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-12">
      <h2 class="my-12">{{ $t("contact.headline") }}</h2>
      <p class="text-justify mb-12">
        {{ $t("contact.text") }}
      </p>

      <contact-form :formColor="'#B2C8D5'" :formBtnColor="'#A3D4DB'" />

    </v-container>
  </v-container>
</template>

<script>
import ContactForm from "@/components/Form";

export default {
  components: {
    ContactForm
  }
};
</script>
