<!--
 Name: Papers.vue
 Description: View to display the paper page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2023-06-28
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="pb-15">
      <h2 class="mt-12 mb-5">{{ $t("papers.headline") }}</h2>
      <v-divider :thickness="5" color="primary"></v-divider>
      <!-- search bar -->
      <v-row class="mt-5 mb-0">
        <v-spacer></v-spacer>
        <v-col cols="12" md="4">
          <v-text-field
            id="paper-search-field"
            v-model="search"
            :label="$t('papers.search')"
            single-line
            clearable
            variant="outlined"
            hide-details
            append-inner-icon="mdi-magnify"
          />
        </v-col>
      </v-row>

      <!-- papers list -->
      <!-- key publications -->
      <h2 class="mt-12 mb-7">
        {{ $t("papers.keyPublications") }}
      </h2>
      <paper-item
        v-for="paper in filteredPublications.keyPublications"
        :key="paper.id"
        :paper="paper"
        class="mb-16"
      />
      <!-- further publications -->
      <h2 class="mt-12 mb-7">
        {{ $t("papers.furtherPublications") }}
      </h2>
      <paper-item
        v-for="paper in filteredPublications.furtherPublications"
        :key="paper.id"
        :paper="paper"
        class="mb-16"
      />
    </v-container>
  </v-container>
</template>

<script>
// The data can be localized if needed. But for now, we only provide the publications info in a single language
// Order of the papers is determined by the publications.json file
// Ids are used to identify pictures in the assets/papers folder
import PaperItem from "@/components/PaperItem";

import publications from '@/data/publications.json';

export default {
  components: {
    PaperItem
  },
  data: () => ({
    search: ""
  }),
  methods: {
    stringContainsSearchInput (testString) {
      return testString.toLowerCase().includes(this.search.toLowerCase())
    },
    publicationIsShown (publication) {
      return this.stringContainsSearchInput(publication.title) ||
        this.stringContainsSearchInput(publication.description) ||
        publication.journal && this.stringContainsSearchInput(publication.journal) ||
        publication.book && this.stringContainsSearchInput(publication.book) ||
        publication.authors.some(author => this.stringContainsSearchInput(author)) ||
        this.stringContainsSearchInput(publication.year.toString())
    }
  },
  computed: {
    filteredPublications () {
      if (!this.search) return publications
      return {
        keyPublications: publications.keyPublications
          .filter(publication => this.publicationIsShown(publication)),
        furtherPublications: publications.furtherPublications
          .filter(publication => this.publicationIsShown(publication)),
      }
    }
  }
};
</script>

<style>
.v-text-field--outlined fieldset {
  border-width: 2px !important;
}

.v-text-field--outlined:hover fieldset {
  border-color: #57509c !important;
}
</style>
