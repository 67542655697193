<!--
 Name: Certificates.vue
 Description: View to display the certificates page.
 Created by: Jana Schumann on 2023-11-14
 Last edited by: Jana Schumann on 2023-11-14
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container>
      <!-- intro -->
      <h2 class="my-12">{{ $t("certificates.headline") }}</h2>
      <p class="text-justify mb-12">
        {{ $t("certificates.text") }}
      </p>
       <v-row class="mb-12">
        <v-col cols="12">
          <v-img
            :src="$i18n.locale === 'de' ? certification_de : certification_en"
            max-width="400"
          />
          <p class="mt-5">
              <a
                href="https://neotiv.com/static/pdfs/ISO13485-certificate-neotiv-english.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'en'"
                >{{ $t("certificates.download") }}</a
              >
              <a
                href="https://neotiv.com/static/pdfs/ISO13485-certificate-neotiv-german.pdf"
                target="_blank"
                rel="noopener noreferrer"
                v-if="$i18n.locale === 'de'"
                >{{ $t("certificates.download") }}</a
              >
            </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  computed: {
    certification_de() {
      return require("@/assets/images/certificates/ISO13485-certificate-neotiv-german.jpg");
    },
    certification_en() {
      return require("@/assets/images/certificates/ISO13485-certificate-neotiv-english.jpg");
    }
  }
};
</script>
