<!--
 Name: Form.vue
 Description: Component to display the contact form.
 Created by: Jana Schumann on 2019-11-06
 Last edited by: Lokeswari Madhusudhana on 2023-09-08
-->

<template>
  <div>
    <v-col cols="12" class="pa-0">
      <v-card flat class="contact-form" :style="{ backgroundColor: formColor }">
        <v-container>
          <v-form v-model="valid" ref="form" validate-on="input lazy">
            <v-text-field
              :label="$t('contact.name')"
              v-model="name"
              :rules="nameRules"
              light
              variant="solo"
            >
            </v-text-field>
            <v-text-field
              :label="$t('contact.email')"
              v-model="email"
              :rules="emailRules"
              light
              variant="solo"
            >
            </v-text-field>
            <v-textarea
              :label="$t('contact.message')"
              v-model="message"
              :rules="messageRules"
              light
              variant="solo"
            >
            </v-textarea>

            <!-- newsletter checkbox -->
            <v-row no-gutters v-if="newsletter" class="my-0">
              <v-col cols="12">
                <v-checkbox v-model="newsletterChecked" color="white" class="contact-form-checkbox" dark>
                  <template v-slot:label>
                    <div class="text-white ml-3">
                      <p class="text-uppercase mb-1">{{ $t("contact.optional_field") }}</p>
                      <b>{{ $t("contact.newsletter-yes") }}</b>
                      <p>
                        {{ $t("contact.newsletter-no") }}
                        <a
                          :href="'https://unsubscribe.newsletter2go.com/form.html?n2g=' + $t('newsletter2go.unsubscribe_code')"
                          class="text-white"
                          @click.stop=""
                        >{{ $t("contact.newsletter-unsubscribe") }}</a>.
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <!-- privacy policy checkbox -->
            <v-row no-gutters>
                <v-checkbox
                  v-model="emailChecked"
                  :rules="emailCheckedRules"
                  class="contact-form-checkbox"
                >
                <template v-slot:label>
                  <div class="text-white ml-3">
                    {{ $t("contact.rightsText_a") }}
                    <router-link
                      :to="'/' + $i18n.locale + '/data'"
                      class="text-white"
                    >{{ $t("contact.rightsText_b") }}</router-link>{{ $t("contact.rightsText_c") }}
                  </div>
                </template>
                </v-checkbox>
            </v-row>

            <!-- contact button -->
            <v-row justify="center" no-gutters>
              <v-btn
                elevation="2"
                size="large"
                width="100%"
                :style="{ backgroundColor: formBtnColor }"
                class="mt-5 text-white"
                @click="submitContactForm"
                :disabled="disableValidation"
                >{{ $t("contact.button") }}</v-btn
              >
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-col>

    <!-- contact form dialogs -->
    <v-dialog v-model="emailDialogSuccess" max-width="500px">
      <v-card class="pa-3">
        <v-card-title>
          <b>{{ $t("newsletter2go.success_title") }}</b>
        </v-card-title>
        <v-card-text class="mt-3 text-grey-darken-1">
          {{ $t("newsletter2go.success_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="flat" @click="emailDialogSuccess = false" min-width="25%">
            {{ $t("newsletter2go.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="emailDialogError" max-width="500px">
      <v-card class="pa-3">
        <v-card-title>
          <b>{{ $t("newsletter2go.error_title") }}</b>
        </v-card-title>
        <v-card-text class="mt-3 text-grey-darken-1">
          {{ $t("newsletter2go.error_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="flat" @click="emailDialogError = false" min-width="25%">
            {{$t("newsletter2go.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from 'qs';

export default {
  props: ["formColor", "formBtnColor", "newsletter"],
  data () {
    return {
      valid: false,
      name: "",
      nameRules: [v => !!v || this.$i18n.t("contact.name_required")],
      email: "",
      emailRules: [
        v => !!v || this.$i18n.t("contact.email_required"),
        v =>
          /^\S+@\S+\.\S+$/.test(v) ||
        this.$i18n.t("contact.email_valid")
      ],
      message: "",
      messageRules: [v => !!v || this.$i18n.t("contact.message_required")],
      emailChecked: false,
      emailCheckedRules: [v => v || this.$i18n.t("contact.privacy_policy_required")],
      emailDialogError: false,
      emailDialogSuccess: false,
      newsletterChecked: false
    }
  },
  methods: {
    async submitContactForm() {
      // UX designer requirement to make the button always enabled
      if (!(await this.$refs.form.validate()).valid) {
        return;
      }
      const formData = {
        Name: this.name,
        Email: this.email,
        Message: this.message,
        Note: this.$route.name,
        Language: this.$i18n.locale,
        Date: this.formattedDate
      }
      const formDataEncoded = qs.stringify(formData)
      try {
        await this.$axios.post("/Nodemailer/SendEmail", formDataEncoded);
        if (this.newsletterChecked) {
          await this.submitHomeForm();
        }

        this.emailDialogSuccess = true;
        this.$refs.form.reset();
      } catch (error) {
        this.emailDialogError = true;
      }
    },
    submitHomeForm() {
      return axios({
        method: "post",
        baseURL: "https://api.newsletter2go.com/",
        url: `forms/submit/${this.$t("newsletter2go.code")}`,
        data: {
          recipient: {
            email: this.email
          }
        }
      })
    }
  },
  watch: {
    // fix for https://github.com/vuetifyjs/vuetify/issues/8571
    "$i18n.locale"() {
      this.$refs.form.resetValidation();
    }
  },
  computed: {
    disableValidation() {
      if (this.valid === false) {
        return true
      }
      return false
    },
    formattedDate () {
      const currentDate = new Date()
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear();
      return `${day}.${month}.${year}`;
    }
  }
};
</script>

<style>
.contact-form {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.contact-form .v-input .v-messages__message {
  font-size: 17px !important;
  line-height: 17px;
}

.contact-form .v-input .v-text-field__details {
  min-height: 17px;
}

.contact-form-checkbox .v-selection-control {
  align-items: start;
  color: white;
}

.contact-form-checkbox .v-selection-control .v-label {
  width: 100% !important;
}

.v-btn--disabled {
    background-color: rgba(0,0,0,.12)!important;
}

.v-input--error:not(.v-input--disabled) .v-input__details .v-messages {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
</style>