<!--
 Name: Awards.vue
 Description: View to display the awards page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2023-06-27
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container>
      <!-- intro -->
      <h2 class="my-12">{{ $t("awards.headline") }}</h2>
      <p class="mb-15">{{ $t("awards.text") }}</p>

      <!-- novartis -->
      <v-row class="mb-12">
        <v-col cols="12" md="6">
          <div class="square-left bg-primary mb-8"></div>
          <h3>{{ $t("awards.novartis_title") }}</h3>
          <p class="text-primary mb-3">{{ $t("awards.novartis_date") }}</p>
          <p class="mb-3">{{ $t("awards.novartis_text_1") }}</p>
          <p class="mb-3">{{ $t("awards.novartis_text_2") }}</p>
        </v-col>
        <v-col cols="12" md="5" offset-md="1">
          <v-img
            :src="novartis_dgp"
            class="my-12"
            height="85"
          ></v-img>
        </v-col>
      </v-row>

      <!-- kfw -->
      <v-row class="mb-12">
        <v-col cols="12" md="6">
          <div class="square-left bg-primary mb-8"></div>
          <h3>{{ $t("awards.kfw_title") }}</h3>
          <p class="text-primary mb-3">{{ $t("awards.kfw_date") }}</p>
          <p class="mb-3">{{ $t("awards.kfw_text") }}</p>
        </v-col>
        <v-col cols="12" md="5" offset-md="1">
          <v-img
            :src="kfw"
            class="my-12"
            height="100"
          />
        </v-col>
      </v-row>

      <!-- iq mitteldeutschland -->
      <v-row class="mb-12">
        <v-col cols="12" md="6">
          <div class="square-left bg-primary mb-8"></div>
          <h3>{{ $t("awards.iq_mitteldeutschland_title") }}</h3>
          <p class="text-primary mb-3">
            {{ $t("awards.iq_mitteldeutschland_date") }}
          </p>
          <p class="mb-3">{{ $t("awards.iq_mitteldeutschland_text") }}</p>
        </v-col>
        <v-col cols="12" md="5" offset-md="1">
          <v-img
            :src="iq_mitteldeutschland"
            class="my-12"
            height="60"
          ></v-img>
        </v-col>
      </v-row>

      <!-- iq magdeburg -->
      <v-row class="mb-12">
        <v-col cols="12" md="6">
          <div class="square-left bg-primary mb-8"></div>
          <h3>{{ $t("awards.iq_md_title") }}</h3>
          <p class="text-primary mb-3">{{ $t("awards.iq_md_date") }}</p>
          <p class="mb-3">{{ $t("awards.iq_md_text") }}</p>
        </v-col>
        <v-col cols="12" md="5" offset-md="1">
          <v-img
            :src="iq_magdeburg"
            class="my-12"
            height="60"
            ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  computed: {
    iq_magdeburg() {
      return require("@/assets/images/awards/iq_md_2018.png");
    },
    iq_mitteldeutschland() {
      return require("@/assets/images/awards/iq_mitteldeutschland_2018.png");
    },
    kfw() {
      return require("@/assets/images/awards/kfw_2018.png");
    },
    novartis_dgp() {
      return require("@/assets/images/awards/novartis_dgp_2019.svg");
    }
  }
};
</script>
