<!--
 Name: Hero.vue
 Description: Component to display the hero section.
 Created by: Jana Schumann on 2019-11-06
 Last edited by: Jana Schumann on 2023-08-04
-->

<template>
  <div :style="{ backgroundColor: heroColor, height: '690px' }">
    <v-row no-gutters>
      <!-- hero text -->
      <v-col cols="11" md="5" offset="1">
        <v-row class="mt-12 pt-12" no-gutters>
          <v-col cols="11">
            <p class="hero-title text-white">
              {{ title }}
            </p>
            <p class="hero-text text-white mt-12">{{ text }}</p>
          </v-col>
        </v-row>
      </v-col>
      <!-- v-col in required to make it work on the smaller devices in safari -->
      <!-- hidden-sm-and-down doesn't work with d-flex -->
      <v-col
        v-if="$vuetify.display.mdAndUp"
        cols="6"
        class="justify-end d-flex"
      >
        <v-img
          :src="image" cover height="690px"
        />
      </v-col>
    </v-row>
    <v-row class="hidden-sm-and-down mt-0" justify="center" no-gutters v-if="!moreBtnHidden">
      <v-btn
        @click="scroll"
        :style="{ backgroundColor: heroColor}"
        class="more-btn text-white"
        >{{ $t("hero.more") }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["title", "text", "heroColor", "image", "ratio", "moreBtnHidden"],
  methods: {
    scroll() {
      const element = document.getElementById('all');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style>
.more-btn {
  top: -80px;
  font-size: 18px !important;
}

.hero-title {
  font-size: 40px;
  line-height: 1.25;
}

.hero-text {
  font-size: 24px;
  line-height: 1.25;
}

@media only screen and (max-width: 600px) {
  .hero-title {
    font-size: 30px;
  }
}
</style>
