<!--
 Name: Physicians.vue
 Description: View to display the physicians page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2023-06-02
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- hero graphic -->
    <hero-component
      :title="$t('hero.physicians-title')"
      :heroColor="'#6DC1B9'"
      :image="require('@/assets/images/physicians/hero.svg')"
      :ratio="0.84"
    >
    </hero-component>
    <v-container class="mb-12" id="all">
      <h2 class="my-12 text-center">{{ $t("physicians.headline") }}</h2>
      <p>{{ $t("physicians.text-1") }}</p>
      <p>{{ $t("physicians.text-2") }}</p>

      <!-- indication -->
      <v-row class="my-12">
        <v-col cols="12" md="6" class="mb-12">
          <div class="square-left clinic_green mb-8"></div>
          <h3 class="mb-5">{{ $t("physicians.indication-headline") }}</h3>
          <p class="mb-5">{{ $t("physicians.indication-text-1") }}</p>
          <p class="pa-5 clinic_green_light clinic_green_dark--text font-weight-medium physicians-text-block">
            {{ $t("physicians.indication-text-2") }}
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            :src="indication"
            cover
          />
        </v-col>
      </v-row>

      <!-- general practitioner -->
      <v-row class="my-12">
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-img
            :src="pract"
            cover
          />
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2" class="mb-12">
          <div class="square-left clinic_green mb-8"></div>
          <h3 class="mb-5">{{ $t("physicians.pract-headline") }}</h3>
          <p class="mb-5">{{ $t("physicians.pract-text-1") }}</p>
          <p class="pa-5 clinic_green_light clinic_green_dark--text font-weight-medium physicians-text-block">
            {{ $t("physicians.pract-text-2") }}
          </p>
        </v-col>
      </v-row>

      <!-- specialist -->
      <v-row class="my-12">
        <v-col cols="12" md="6" class="mb-12">
          <div class="square-left clinic_green mb-8"></div>
          <h3 class="mb-5">{{ $t("physicians.specialist-headline") }}</h3>
          <p class="mb-5">{{ $t("physicians.specialist-text-1") }}</p>
          <p class="pa-5 clinic_green_light clinic_green_dark--text font-weight-medium physicians-text-block">
            {{ $t("physicians.specialist-text-2") }}
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            :src="specialist"
            cover
          />
        </v-col>
      </v-row>

      <!-- treatment -->
      <v-row class="my-12">
        <v-col cols="12" md="6">
          <v-img
            :src="treatment"
            cover
          />
        </v-col>
        <v-col cols="12" md="6" class="mb-12">
          <div class="square-left clinic_green mb-8"></div>
          <h3 class="mb-5">{{ $t("physicians.treatment-headline") }}</h3>
          <p class="mb-5">{{ $t("physicians.treatment-text-1") }}</p>
          <p class="pa-5 clinic_green_light clinic_green_dark--text font-weight-medium physicians-text-block">
            {{ $t("physicians.treatment-text-2") }}
          </p>
        </v-col>
      </v-row>
    </v-container>

    <!-- contact -->
    <section class="clinic_green">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("scientists.individual-solution") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/contact'"
            >{{ $t("scientists.contact-us") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import HeroComponent from "@/components/Hero";

export default {
  components: {
    HeroComponent
  },
  computed: {
    indication() {
      return require("@/assets/images/physicians/indication.svg");
    },
    pract() {
      return require("@/assets/images/physicians/general_practitioner.svg");
    },
    specialist() {
      return require("@/assets/images/physicians/specialist.svg");
    },
    treatment() {
      return require("@/assets/images/physicians/treatment.svg");
    }
  }
};
</script>

<style scoped>
.physicians-text-block {
  line-height: 1.35;
}
</style>
