/**
 * Name: main.js
 * Description: Main entry point.
 * Created by: Jana Schumann on 2019-11-05
 * Last edited by: Lokeswari Madhusudhana on 2023-09-04
 */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import { createI18n } from "vue-i18n";
import messages from "./lang";
import axiosInstance from './api';

//Vue.config.productionTip = false;

export const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
  allowComposition: true,
  inheritLocale: false
});

const app = createApp(App)
app.use(vuetify)
app.use(i18n)
app.use(router)
app.config.globalProperties.$axios = axiosInstance
router.isReady().then(() => {
  app.mount('#app')
})