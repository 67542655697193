/**
 * Name: vuetify.js
 * Description: Vuetify handler.
 * Created by: Jana Schumann on 2019-11-12
 * Last edited by: Lokeswari Madhusudhana on 2023-06-16
 */

import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: "mdi" // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#57509C",
          'bg-primary': "#57509C",
          accent: "#57509C",
          violet_light: "#DDDBEB",
          error_blue: "#91b1c4",
          clinic_green: "#6DC1B9",
          clinic_green_light: "#B4DFDA",
          clinic_green_dark: "#089688",
          blue_light: "#91D1DE",
          dialog_overlay_color: "#1D1D1D",
          dialog_text_color: "#8B8B8B"
        }
      }
    }
  }
});
export default vuetify;