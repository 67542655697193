<!--
 Name: Scientists.vue
 Description: View to display the scientists page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2023-06-27
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- hero graphic -->
    <hero-component
      :title="$t('hero.scientists-title')"
      :heroColor="'#91D1DE'"
      :image="require('@/assets/images/scientists/hero.svg')"
    >
    </hero-component>
    <v-container class="mb-12" id="all">
      <h2 class="my-12 text-center">{{ $t("scientists.headline") }}</h2>
      <p class="mb-12">{{ $t("scientists.text") }}</p>

      <!-- browser -->
      <v-row class="my-0">
        <v-col cols="12" md="6" order="2" order-md="1" class="my-12">
          <v-img :src="browser" height="250px"/>
        </v-col>
        <v-col
          cols="12"
          md="5"
          offset-md="1"
          order="1"
          order-md="2"
          class="my-12"
        >
          <div class="square-left mb-8"></div>
          <h3 class="mb-3">{{ $t("scientists.browser-headline") }}</h3>
          <p class="mb-12">{{ $t("scientists.browser-text") }}</p>
        </v-col>
      </v-row>

      <!-- app -->
      <v-row class="my-12">
        <v-col
          cols="12"
          md="5"
          offset-md="1"
          order="2"
          order-md="1"
          class="mb-12"
        >
          <div class="square-left mb-8"></div>
          <h3 class="mb-3">{{ $t("scientists.app-headline") }}</h3>
          <p class="mb-12">{{ $t("scientists.app-text") }}</p>
        </v-col>
        <v-col cols="12" md="6" class="mb-5" order="2" order-md="1">
          <v-img
            :src="app"
            height="280"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- contact -->
    <section class="section-color">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("scientists.individual-solution") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/contact'"
            >{{ $t("scientists.contact-us") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import HeroComponent from "@/components/Hero";

export default {
  components: {
    HeroComponent
  },
  computed: {
    app() {
      return require("@/assets/images/scientists/app.svg");
    },
    browser() {
      return require("@/assets/images/scientists/browser.svg");
    }
  }
};
</script>
<style scoped>
  .section-color {
    background-color: #91D1DE;
  }
</style>
