/**
 * Name: index.js
 * Description: Language handler.
 * Created by: Jana Schumann on 2019-11-12
 * Last edited by: Jana Schumann on 2019-11-14
 */

import de from "./translations/de";
import en from "./translations/en";

export default {
  de,
  en
};
