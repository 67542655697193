<!--
 Name: SideMenu.vue
 Description: Side menu for small devices.
 Created by: Milena Malysheva on 2021-11-03
 Last edited by: Lokeswari Madhusudhana on 2023-06-29
-->

<template>
  <!-- mobile menu -->
  <!-- hide-overlay - overlay isn't hidden when user zooms out -->
  <v-navigation-drawer
    v-model="openSideMenuLocal"
    location="right"
    temporary
    scrim="false"
    class="side-menu-content"
  >
    <v-list :opened="opened" @update:opened="opened = $event.slice(-1)">
      <v-list-group color="black" :value="$t(menu.headline)" v-for="menu in mainMenu" :key="menu.headline">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
            >
          <b>{{ $t(menu.headline) }}</b>
          </v-list-item>
          </template>

          <v-list-item
            v-for="(item, i) in menu.items"
            :key="i"
            :value="$t(item.title)"
          >
            <a
              v-if="item.link"
              :href="item.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t(item.title) }}
            </a>

            <!-- internal link -->
            <router-link
              v-else
              :to="{ name: item.routeName }"
            >
              {{ $t(item.title) }}
            </router-link>
          </v-list-item>
        </v-list-group>

      <!-- language switcher -->
      <v-list-group color="black">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title>
              <b>{{ $t('header.language') }}</b>
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item
          v-for="language in $i18n.availableLocales"
          :key="language"
          :to="{ name: $route.name, params: { lang: language } }"
          @click="$i18n.locale = language"
        >
          <v-list-item-title class="ml-5">
            {{ $i18n.messages[language].language }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    openSideMenu: Boolean,
    mainMenu: Array
  },
  data: () => ({
    openSideMenuLocal: false,
    opened: []
  }),
  watch: {
    openSideMenu(value) {
      if (value) this.openSideMenuLocal = true
    },
    openSideMenuLocal(value) {
      if (!value) this.$emit('update:openSideMenu', false)
    }
  }
};
</script>

<style>
  .v-list-group__header.v-list-item--link:before {
    background-color: inherit;
  }

  .side-menu-content a {
    text-decoration: none;
    color: inherit !important;
  }
</style>
