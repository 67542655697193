<!--
 Name: Data.vue
 Description: View to display the data page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Jana Schumann on 2023-06-29
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-5">
      <h2 class="my-5">{{ $t("data.headline") }}</h2>
      <p class="mb-3">
        {{ $t("data.privacy_text") }}
      </p>
      <br />
      <h3>{{ $t("legal.data") }}</h3>
      <p class="mb-3">
        Martin Bastius
        <br />
        heyData GmbH
        <br />
        Schützenstraße 5
        <br />
        10117 Berlin, {{ $t("legal.country") }}
        <br />
        {{ $t("legal.email") }}:
        <a href="mailto:datenschutz@heydata.eu">datenschutz@heydata.eu</a>
        <br />
        {{ $t("legal.phone") }}: +49 894 1325 320
      </p>

      <br />
      <h3>{{ $t("data.authority") }}</h3>
      <p class="mb-3">
        {{ $t("data.representative") }}
        <br />
        {{ $t("data.address") }}: Leiterstraße 9, 39104 Magdeburg,
        {{ $t("legal.country") }}
        <br />
        {{ $t("legal.post") }} Postfach 1947, 39009 Magdeburg,
        {{ $t("legal.country") }}
        <br />
        Web:
        <a
          href="https://datenschutz.sachsen-anhalt.de/landesbeauftragter/kontakt/"
          >datenschutz.sachsen-anhalt.de</a
        >
        <br />
        {{ $t("legal.email") }}:
        <a href="mailto:poststelle@lfd.sachsen-anhalt.de"
          >poststelle@lfd.sachsen-anhalt.de</a
        >
        <br />
        {{ $t("legal.phone") }}: +49 391 8180 30
        <br />
        Freecall: +49 800 9153 190 (DTAG fixed network)
        <br />
        Fax: +49 391 8180 333
      </p>

      <br />
      <h3>Newsletter</h3>
      <p class="mb-3">
        {{ $t("data.newsletter_text") }}
      </p>

      <br />
      <h3>{{ $t("data.ticket_system_headline") }}</h3>
      <p class="mb-3">
        {{ $t("data.ticket_system_text") }}
      </p>

      <br />
      <h3>{{ $t("data.contact_form_headline") }}</h3>
      <p class="mb-3">
        {{ $t("data.contact_form_text") }}
      </p>

      <br />
      <h3>{{ $t("data.rights_headline") }}</h3>
      <p class="mb-3">
        {{ $t("data.rights_text") }}
      </p>

      <br />
      <h3>{{ $t("data.hosting_headline") }}</h3>
      <p class="mb-3">
        {{ $t("data.hosting_text") }}
      </p>

      <br />
      <h3>{{ $t("data.usage_headline") }}</h3>
      <p class="mb-3">{{ $t("data.usage_text") }}</p>

      <br />
      <h3>{{ $t("data.collection_headline") }}</h3>
      <p class="mb-3">{{ $t("data.collection_usage_data_text") }}</p>
      <p class="mb-3">{{ $t("data.collection_cookies_text") }}</p>

      <br />
      <h3>{{ $t("data.office_bookings_headline") }}</h3>
      <p class="mb-3">{{ $t("data.office_bookings_text") }}</p>
      <p class="mb-3">
        {{ $t("data.office_bookings_objection") }}
        <a
          href="https://privacy.microsoft.com/de-de/privacystatement"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.microsoft.com/de-de/privacystatement
        </a>
      </p>

      <br />
      <h3>{{ $t("data.cookies_usage") }}</h3>
      <p class="mb-3">
        <!-- <v-data-table
          class="cookies pt-8"
          :headers="headers"
          :items="cookieprops"
          hide-default-footer
        >
        </v-data-table> -->
        <v-table class="cookies pt-8">
          <thead>
            <tr>
              <th>{{ this.$i18n.t("data.cookies_name") }}</th>
              <th>Hoster</th>
              <th>{{ this.$i18n.t("data.cookies_purpose") }}</th>
              <th>Opt-In</th>
              <th>{{ this.$i18n.t("data.cookies_expire") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in cookieprops"
              :key="item.cookiename"
            >
              <td>{{ item.cookiename }}</td>
              <td>{{ item.hoster }}</td>
              <td>{{ item.purpose }}</td>
              <td>{{ item.optin }}</td>
              <td>{{ item.expire }}</td>
            </tr>
          </tbody>
        </v-table>
      </p>
      <br />
      <h3>{{ $t("data.opt_out_headline") }}</h3>
      <p class="mb-3">{{ $t("data.opt_out_text") }}
        <router-link :to="'/' + $i18n.locale">
          <span @click="resetCookieLocalStorage">{{ $t("data.opt_out_link") }}</span>
        </router-link>
      </p>
    </v-container>
  </v-container>
</template>

<script>
//import { VDataTable } from 'vuetify/labs/VDataTable'
export default {
  components: {
    //VDataTable,
  },
  methods: {
    resetCookieLocalStorage() {
      // the cookie banner will be shown and the non-essential cookie checkboxes will remain checked if they were checked before
      localStorage.removeItem('cookie:essential')
      window.scrollTo(0, 0);
    }
  },
  // https://github.com/vuetifyjs/vuetify/issues/8571
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("data.cookies_name"),
          value: "cookiename",
          width: "20%",
          sortable: false
        },
        {
          text: "Hoster",
          value: "hoster",
          width: "10%",
          sortable: false
        },
        {
          text: this.$i18n.t("data.cookies_purpose"),
          value: "purpose",
          sortable: false
        },
        {
          text: "Opt-In",
          value: "optin",
          width: "15%",
          sortable: false
        },
        {
          text: this.$i18n.t("data.cookies_expire"),
          value: "expire",
          width: "10%",
          sortable: false
        }
      ];
    },
    cookieprops() {
      return [
        {
          cookiename: "_pk_id",
          hoster: "neotiv GmbH",
          purpose: this.$i18n.t("data.cookies_neotiv_id_purpose"),
          optin: this.$i18n.t("data.cookies_yes"),
          expire: "1 " + this.$i18n.t("data.cookies_year")
        },
        {
          cookiename: "_pk_ses",
          hoster: "neotiv GmbH",
          purpose: this.$i18n.t("data.cookies_neotiv_ses_purpose"),
          optin: this.$i18n.t("data.cookies_yes"),
          expire: "30 " + this.$i18n.t("data.cookies_minutes")
        },
        // {
        //   cookiename: "piwik_ignore",
        //   hoster: "neotiv GmbH",
        //   purpose: i18n.t("data.cookies_piwik_ignore_purpose"),
        //   optin: i18n.t("data.cookies_yes"),
        //   expire: "2 " + i18n.t("data.cookies_years")
        // },
        // {
        //   cookiename: "MATOMO_SESSID",
        //   hoster: "neotiv GmbH",
        //   purpose: i18n.t("data.cookies_matomo_sessid_purpose"),
        //   optin: "-",
        //   expire: i18n.t("data.cookies_session")
        // }
      ];
    }
  }
};
</script>

<style scoped>
  .cookies table thead th {
    font-size: inherit!important;
    vertical-align: top;
    width: 10px;
  }
  .cookies table tbody td {
    font-size: .875rem;
    height: 48px
  }

  .cookies table tbody tr:hover {
    background-color: #EEEEEE;
  }
</style>
