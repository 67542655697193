<!--
 Name: Studies.vue
 Description: View to display the studies page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2024-04-16
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- intro -->
    <section id="all">
      <v-container class="mb-16">
        <h2 class="my-12">
          {{ $t("studies.headline") }}
        </h2>
        <p class="text-justify mb-15">
          {{ $t("studies.text") }}
        </p>

        <!-- studies list -->
        <!-- validation studies -->
        <div class="square-left bg-primary mt-12 mb-6"/>
        <h3 class="mb-10">
          {{ $t("studies.studiesList.validationStudies.title") }}
        </h3>

        <!-- study idx in the studies list is the id used to fetch the corresponding study logo -->
        <v-row
          v-for="(study, idx) in $tm('studies.studiesList.validationStudies.list')"
          :key="`validation-study-${idx}`"
        >
          <!-- image -->
          <v-col
            cols="12"
            sm="3"
            align-self="center"
          >
            <v-img
              :src="require(`@/assets/images/studies/validation_study_${idx}.png`)"
              cover
            />
          </v-col>
          <!-- study details -->
          <v-col
            cols="12"
            sm="8"
            offset-sm="1"
            class="pb-10"
          >
            <!-- title -->
            <h3>
              {{ $rt(study.title) }}
            </h3>

            <!-- location -->
            <p class="text-grey small mb-0">
              {{ $rt(study.location) }}
            </p>

            <!-- duration -->
            <p class="small mb-3">
              {{ $rt(study.duration) }}
            </p>

            <!-- description -->
            <p class="text-justify mb-5">
              {{ $rt(study.description) }}
            </p>

            <a
              target="_blank"
              :href="$rt(study.link)"
              rel="noopener noreferrer"
              class="text-decoration-none"
            >
              > {{ $t("studies.studyLink") }}
            </a>
          </v-col>
        </v-row>

        <!-- selected scientific collaborations -->
        <div class="square-left bg-primary mt-16 mb-6"/>
        <h3 class="mb-12">
          {{ $t("studies.studiesList.selectedCollaborations.title") }}
        </h3>

        <v-row class="mb-15">
          <v-col
            v-for="(study, idx) in $tm('studies.studiesList.selectedCollaborations.list')"
            :key="`collaboration-${idx}`"
            cols="12"
            md="4"
            class="text-center pb-13"
          >
            <!-- image -->
            <v-row
              justify="center"
              class="mb-5"
            >
              <v-img
                :src="require(`@/assets/images/studies/collaboration_${idx}.png`)"
                height="150"
                max-width="80%"
              />
            </v-row>

            <!-- study details -->
            <!-- title -->
            <h3>
              {{ $rt(study.title) }}
            </h3>

            <!-- location -->
            <p class="text-grey small mb-0">
              {{ $rt(study.location) }}
            </p>

            <a
              target="_blank"
              :href="$rt(study.link)"
              rel="noopener noreferrer"
              class="text-decoration-none"
            >
              > {{ $t("studies.studyLink") }}
            </a>
          </v-col>
        </v-row>

        <!-- CiSc studies -->
        <div class="square-left bg-primary mt-12 mb-6"/>
        <h3 class="mb-4">
          {{ $t("studies.studiesList.citizenScienceStudies.title") }}
        </h3>
        <v-row
          class="my-5"
        >
          <v-col
            cols="12"
            md="5"
          >
            <v-img
              :src="cisc_images"
              height="70"
            />
          </v-col>
        </v-row>
        <p>
          {{ $t("studies.studiesList.citizenScienceStudies.text") }}
          <a
              target="_blank"
              href="https://www.exploring-memory.org/"
            >{{ $t("studies.studiesList.citizenScienceStudies.here-link") }}</a>
            {{ $t("studies.studiesList.citizenScienceStudies.extra-text") }}
        </p>

      </v-container>
    </section>

    <!-- science behind neotiv -->
    <section class="bg-primary">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("studies.science-behind-neotiv") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/papers'"
            >{{ $t("studies.papers") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>
<script>

export default ({
  name: "Studies",
  computed: {
    cisc_images() {
      return require("@/assets/images/studies/ci_sci.svg");
    }
  }
})
</script>

