<!--
 Name: Researchers.vue
 Description: View to display the researchers page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Lokeswari Madhusudhana on 2023-06-21
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- hero graphic -->
    <hero-component
      :title="$t('hero.researchers-title')"
      :heroColor="'#57509C'"
      :image="require('@/assets/images/researchers/hero.svg')"
      :ratio="1"
    >
    </hero-component>
    <v-container class="mb-15" id="all">
      <h2 class="my-12 text-center">{{ $t("researchers.headline") }}</h2>
      <p>{{ $t("researchers.text") }}</p>

      <!-- population -->
      <v-row class="mt-12 pb-15">
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-img
            :src="population"
            class="mt-6"
            height="170"
          />
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-5">{{ $t("researchers.population-headline") }}</h3>
          <ul>
            <li class="mb-2">{{ $t("researchers.population-text-1") }}</li>
            <li>{{ $t("researchers.population-text-2") }}</li>
          </ul>
        </v-col>
      </v-row>

      <!-- decline -->
      <v-row class="mt-12 pb-15">
        <v-col cols="12" md="6">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-5">{{ $t("researchers.decline-headline") }}</h3>
          <ul>
            <li class="mb-2">{{ $t("researchers.decline-text-1") }}</li>
            <li>{{ $t("researchers.decline-text-2") }}</li>
          </ul>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            :src="decline"
            class="mt-12"
            height="165"
          />
        </v-col>
      </v-row>

      <!-- stages -->
      <v-row class="mt-12 pb-15">
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-img
            :src="stages"
            class="mt-6"
            height="110"
          />
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-5">{{ $t("researchers.stages-headline") }}</h3>
          <p class="mb-5">{{ $t("researchers.stages-text-1") }}</p>
        </v-col>
      </v-row>

      <!-- group -->
      <v-row class="mt-12 pb-15">
        <v-col cols="12" md="6">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-5">{{ $t("researchers.group-headline") }}</h3>
          <ul>
            <li class="mb-2">{{ $t("researchers.group-text-1") }}</li>
            <li>{{ $t("researchers.group-text-2") }}</li>
          </ul>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            :src="group"
            class="mt-12"
            height="165"
          />
        </v-col>
      </v-row>

      <!-- safety -->
      <v-row class="mt-12">
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-img
            :src="safety"
            class="mt-6"
            height="165"
          />
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
          <div class="square-left bg-primary mb-8"></div>
          <h3 class="mb-5">{{ $t("researchers.safety-headline") }}</h3>
          <p class="mb-5">{{ $t("researchers.safety-text-1") }}</p>
        </v-col>
      </v-row>
    </v-container>

    <!-- contact -->
    <section class="bg-primary">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("scientists.individual-solution") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5"
            :to="'/' + $i18n.locale + '/contact'"
            >{{ $t("scientists.contact-us") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import HeroComponent from "@/components/Hero";

export default {
  components: {
    HeroComponent
  },
  computed: {
    population() {
      return require("@/assets/images/researchers/population.svg");
    },
    decline() {
      return require("@/assets/images/researchers/detection.svg");
    },
    stages() {
      return require("@/assets/images/researchers/stages.svg");
    },
    group() {
      return require("@/assets/images/researchers/group.svg");
    },
    safety() {
      return require("@/assets/images/researchers/safety.svg");
    }
  }
};
</script>
