<!--
 Name: Legal.vue
 Description: View to display the legal page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Milena Malysheva on 2021-12-20
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-15">
      <h2 class="mt-12 mb-15">{{ $t("legal.headline") }}</h2>

      <v-row>
        <v-col>
          <h3>{{ $t("legal.info") }}</h3>
          <p>
            neotiv GmbH
            <br />
            Hegelstrasse 19
            <br />
            39104 Magdeburg, {{ $t("legal.country") }}
          </p>

          <br />
          <h3>{{ $t("legal.represented") }}</h3>
          <p>Chris Rehse</p>

          <p>
            {{ $t("legal.court") }} Amtsgericht Stendal
            <br />
            {{ $t("legal.number") }} HRB 24830
            <br />
            USt-IdNr.: DE 314 824 372
          </p>

          <br />
          <h3>{{ $t("legal.contact") }}</h3>
          <p>
            {{ $t("legal.email") }}:
            <a href="mailto:contact@neotiv.com">contact@neotiv.com</a>
            <br />
            {{ $t("legal.phone") }}: +49 391 5824 5026
          </p>

          <!--<br />
          <h3>{{ $t("legal.support_contact") }}</h3>
          <p>
            {{ $t("legal.support_email") }}:
            <a href="mailto:support@neotiv.com">support@neotiv.com</a>
            <br />
            {{ $t("legal.support_phone") }}: +49 391 5824 5026
          </p>-->

          <br />
          <h3>{{ $t("legal.content") }}</h3>
          <p>Chris Rehse</p>

          <br />
          <h2>{{ $t("legal.disclaimer") }}</h2>

          <br />
          <h3>{{ $t("legal.disclaimer_content_headline") }}</h3>
          <p>
            {{ $t("legal.disclaimer_content_text") }}
          </p>

          <br />
          <h3>{{ $t("legal.disclaimer_links_headline") }}</h3>
          <p>
            {{ $t("legal.disclaimer_links_text") }}
          </p>

          <br />
          <h3>{{ $t("legal.disclaimer_rights_headline") }}</h3>
          <p>
            {{ $t("legal.disclaimer_rights_text") }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
