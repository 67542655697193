<!--
 Name: Footer.vue
 Description: Component to display the footer.
 Created by: Jana Schumann on 2019-11-06
 Last edited by: Lokeswari Madhusudhana on 2023-06-20
-->

<template>
  <div>
    <v-footer height="auto" id="app-footer">

      <!-- footer content -->
      <v-container class="footer-content">
        <v-row class="small mt-0">
          <v-col
            cols="12"
            sm="6"
            md="2"
            class="mb-3"
            v-for="submenu in menu"
            :key="`footer-${submenu.headline}`"
          >
            <p class="semi-small">
              <b>{{ $t(submenu.headline) }}</b>
            </p>
            <div
              v-for="item in submenu.items"
              :key="`footer-${item.title}`"
              class="footer-item"
            >
              <!-- external link -->
              <a
                v-if="item.link"
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t(item.title) }}
              </a>

              <!-- internal link -->
              <router-link
                v-else-if="item.routeName"
                :to="{ name: item.routeName }"
              >
                {{ $t(item.title) }}
              </router-link>
              

              <div v-else>
                {{ item.text }}
              </div>
            </div>
          </v-col>
          <v-col>
            <v-img
              :src="logo_efre"
              width="300px"
              height="52px"
            />
          </v-col>
        </v-row>

        <p class="text-body-1 mt-8 my-5 text-grey-lighten-1">
          &copy; neotiv GmbH {{ new Date().getFullYear() }} - made with neurons in Magdeburg, Germany
        </p>

      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  props: {
    mainMenu: Array
  },
  computed: {
    logo_efre() {
      return require("@/assets/images/partner/Logo_EFRE.jpg");
    },
    menu() {
      return [
        ...this.mainMenu,
        {
          headline: "footer.software.headline",
          items: [
            { title: "footer.software.care", link: "https://neotiv-care.com" },  
            { title: "footer.software.trials", link: "https://trials.neotiv.com" },
            { title: "footer.software.app-app-store", link: "https://apps.apple.com/de/app/neotivtrials/id1611173081" },
            { title: "footer.software.app-google-play", link: "https://play.google.com/store/apps/details?id=com.neotiv.app.trials" }
          ]
        },
        {
          headline: "footer.contact.headline",
          items: [
            { title: "footer.contact.form", routeName: "Contact" },
            { title: "footer.contact.twitter", link: "https://twitter.com/neotiv_app" }
          ]
        },
        {
          headline: "footer.law.headline",
          items: [
            { title: "footer.law.impressum", routeName: "Legal" },
            { title: "footer.law.data", routeName: "Data" }
          ]
        }
      ]
    }
  }
};
</script>

<style scoped>

/* copied from the styles for <hr> that we had instead of the border before */
#app-footer {
  border-top: 1px solid #ccc;
  background-color: white;
}

.footer-item {
  color: #919191;
  margin-bottom: 8px;
  line-height: 1.1;
}
.footer-item > a {
  text-decoration: none;
  color: inherit;
}

.footer-content a:hover{
  color: black;
}

.footer-content p {
    margin-bottom: 16px;
}
</style>
