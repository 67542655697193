<!--
 Name: HowItWorks.vue
 Description: View to display the how it works page.
 Created by: Jana Schumann on 2019-11-10
 Last edited by: Lokeswari Madhusudhana on 2023-06-20
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <v-container class="mb-12">
      <h2 class="my-12">{{ $t("hiw.headline") }}</h2>
      <p>{{ $t("hiw.text") }}</p>

      <!-- testing principle -->
      <v-row class="my-12">
        <v-col cols="12">
          <div class="square-left bg-primary mb-8"></div>
          <h3>{{ $t("hiw.testing-principle") }}</h3>
        </v-col>
      </v-row>

      <!-- carousel -->
      <v-carousel
        v-model="activeSlide"
        color="grey"
        :height="750"
        id="how-it-works-carousel">
        <v-carousel-item  v-for="slide in slides" :key="slide">
          <v-row align="center" justify="center" class="mt-12">
            <v-img
              :src="require('@/assets/images/hiw/0' + slide + '_screen_' + $i18n.locale + '.svg')"
              height="450px"
            ></v-img>
          </v-row>
          <v-row align="center" justify="center" class="mt-15">
            <v-col cols="11" sm="5">
              <p class="text-center">{{ $t("hiw.slide-" + slide) }}</p>
            </v-col>
          </v-row>
        </v-carousel-item>
        <template v-slot:prev="{ on }">
          <v-btn
            v-if="activeSlide !== 0"
            icon
            color="grey"
            class="prev-arrow"
            @click.stop="prevSlide"
            v-bind="on"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on }">
          <v-btn
            v-if="activeSlide !== slides - 1"
            icon
            color="grey"
            class="next-arrow"
            @click.stop="nextSlide"
            v-bind="on"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </v-carousel>
    </v-container>

    <!-- science behind neotiv -->
    <section class="bg-primary">
      <v-container class="py-12">
        <h3 class="text-white text-center mb-3">
          {{ $t("studies.science-behind-neotiv") }}
        </h3>
        <v-row justify="center" class="mb-12">
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5 px-12"
            :to="'/' + $i18n.locale + '/studies'"
            >{{ $t("footer.science.studies") }}</v-btn
          >
          <v-btn
            variant="outlined"
            size="large"
            color="white"
            class="my-12 mx-5 px-12"
            :to="'/' + $i18n.locale + '/papers'"
            >{{ $t("studies.papers") }}</v-btn
          >
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>
<script>
export default({
  data () {
    return {
      activeSlide: 0,
      slides: 6
    }
  },
  methods: {
    prevSlide() {
      this.activeSlide--;
    },
    nextSlide() {
      this.activeSlide++;
    },
  }
})
</script>


<style>
/*https://jira.neotiv.com/browse/WS-143*/
#how-it-works-carousel .v-carousel__controls__item i {
  font-size: 16px !important;
}

#how-it-works-carousel .v-window__prev button,
#how-it-works-carousel .v-window__next button {
  height: 39px;
  width: 39px;
}

#how-it-works-carousel .v-btn--icon .v-icon {
  --v-icon-size-multiplier: 0.5;
}

#how-it-works-carousel .v-btn {
  border-width: 0px !important;
}

#how-it-works-carousel .prev-arrow {
  left: 16px;
}

#how-it-works-carousel .next-arrow {
  right: 16px;
  left: auto !important;
  position: absolute;
  margin: 0 16px;
  top: calc(50% - 20px);
}

</style>
